import api from '@/api/request'
import useStore from '@/store/pinia'

// 商品系列接口

// 产品通用接口
export function getGeneralProduct(data) {
  return api.get(`/services/product/api/product-collect-praises`, data)
}

// 查看商品数量
export function getProductCount(params) {
  return api.get('/services/product/api/products/count', params)
}

// 查看商品过滤列表
export function getCategoryFind(params) {
  return api.post('/services/product/api/category/find', params)
}

// 查看商品政策
export function getProductPolicy(productId) {
  return api.get(`/services/product/api/product/policy/relation/${productId}`)
}

// 查看商品最低运费
export function getProductFreightMin(params) {
  return api.post(`/services/product/api/freight/min`, params)
}

// menu类目
export function getCategories(params) {
  return api.get(`/services/product/api/categories/tree`, params)
}

// 商品详情页=>根据商品id查询商品信息
export function getProductDetails(id) {
  const { userStore } = useStore()
  // if (userStore.appHomeName === 'PRICOO') {
  //   return api.get(`/services/product/api/products/simplify/${id}`, {}, { zongVersion: 1 })
  // }
  return api.get(`/services/product/api/website/products/simplify/${id}`, {}, { zongVersion: 1 })
}

// 查询购物车列表
export function getcartSpecList(data) {
  return api.get(`/services/product/api/cart-spec`, data)
}

// 加入购物车
export function addCart(data) {
  return api.post(`/services/product/api/cart-specs`, data)
}

// 查询购物车数量
export function getCartCount(data) {
  return api.get(`/services/product/api/cart-specs/count`, data)
}

// 购物车删除
export function cartDeletion(id) {
  return api.del(`/services/product/api/cart-specs/logical/${id}`)
}

// 购物车批量删除
export function cartDeletionAll(ids) {
  return api.del(`/services/product/api/cart-specs/batch/logical`, ids)
}

// 修改购物车数量
export function putCartSpecs(prams) {
  return api.put(`/services/product/api/cart/specs/new`, prams)
}

// 运费查询
export function freightSearch(params) {
  // return api.get('/services/product/api/products/with-expand/'+data)
  return api.post(`/services/product/api/freight/search`, params)
}

// 商品收藏取消收藏
export function productCollectPraises(params, type) {
  type = type || ''
  switch (type) {
    case 'put': // 取消收藏
      return api.put(`/services/product/api/product-collect-praises`, params)
    default: // 收藏
      return api.post(`/services/product/api/product-collect-praises`, params)
  }
}

// 查询我的喜欢数量
export function queryAttentionNum(params) {
  return api.get(`/services/product/api/product-collect-praises/count`, params)
}

// 获取商品列表|推荐
export function getProducts(params) {
  return api.get(`/services/product/api/products`, params, { isHeader: true })
}

// 获取商品列表新
export function getProductsList(params) {
  return api.get(`/services/product/api/mall/products/simple`, params, { isHeader: true })
}

// 获取商品列表新接口兼容pricoo逻辑[20041011]
export function getProductsList2(params) {
  return api.get(`/services/product/api/products/graphql`, params, { isHeader: true })
}

/**
 * 获取产品规格详情信息
 * @typedef {Array<{ specId: number, count: number }>} GetSpecsDetailsListParams
 */
export function getSpecsDetailsList(params) {
  return api.post(`/services/product/api/batch/specs`, params)
}

// 上传评论
export function commentRecords(params) {
  return api.post(`/services/product/api/comment-records `, params)
}

// 查看评论总数
export function recordsProduct(params) {
  return api.get('/services/product/api/comment/records/product', params)
}

// 查看评论列表
export function recordsList(params) {
  return api.get('/services/product/api/comment/records/list', params)
}

// 智能搜索
export function intelligentSearch(params) {
  return api.get('/services/product/api/intelligent', params)
}

// 首页折扣活动
export function getDiscountsList(id) {
  return api.get(`/services/product/api/discounts/product/${id}`)
}

// export function getDiscountsList(id) {
//   return api.get(`/services/product/api/discounts/product/spec{id}`)
// }

// 获取规格展示列表
export function getProductExpand(params) {
  return api.get(`/services/product/api/mall/product/expand`, params)
}


// b-class
export function getBClass(params) {
  return api.get(`/services/product/api/b-class`, params)
}
