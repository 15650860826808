export default {
  "CHANGE LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换语言"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "SHOPPING CART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
  "TRACK MY ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的订单"])},
  "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简体中文"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文"])},
  "Flash Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限时抢购"])},
  "SELL WELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热卖"])},
  "Classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
  "Shopping Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
  "Product information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品信息"])},
  "Unit Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单价"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "Service Charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务费用"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
  "Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "Freight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
  "Check Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
  "PUSH TO YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为你推荐"])},
  "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销量"])},
  "Return Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退单"])},
  "Refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款中"])},
  "Refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已退款"])},
  "To Pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待支付"])},
  "To Ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待送货"])},
  "To Receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待收货"])},
  "To Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待评价"])},
  "Return and Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退换货"])},
  "Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券"])},
  "Available Coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用优惠券"])},
  "Invalid Coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失效优惠券"])},
  "Account Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户设置"])},
  "Basic Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础信息"])},
  "Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货地址"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改密码"])},
  "My Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的关注"])},
  "Help Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
  "View All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有"])},
  "The goods you purchased have been picked up and are waiting for delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你购买的货物已经提货，正等待发货"])},
  "View Logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看物流"])},
  "Receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货人"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])},
  "Order Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
  "Order Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单编码"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
  "Related Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部分类"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
  "Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "Product Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品特点"])},
  "Popular Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门品牌"])},
  "Colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])},
  "Current Deals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前优惠"])},
  "Sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "Results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到结果"])},
  "BestMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["综合"])},
  "Newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新品"])},
  "Price Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格范围"])},
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新品"])},
  "HOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热卖"])},
  "Filtered By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大约"])},
  "results were found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条数据"])},
  "Clear All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除所有"])},
  "people buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人购买"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])},
  "Pages Go to Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页 跳转到"])},
  "GO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去"])},
  "Free Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免运费"])},
  "Cash On Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货到付款"])},
  "Min Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低价格"])},
  "Max Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高价格"])},
  "Star Rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星级评定"])},
  "User feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户反馈"])},
  "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用性"])},
  "FLASH SALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒杀"])},
  "Distance End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["倒计时"])},
  "Buy Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即购买"])},
  "Add to Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到购物车"])},
  "Add to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到愿望清单"])},
  "Added to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加至愿望清单"])},
  "Share product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享产品"])},
  "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货"])},
  "Available Payment Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用的付款方式"])},
  "Minimum Shipping Fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低运费"])},
  "Cash on Delivery Available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货到付款"])},
  "Home Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货上门"])},
  "Return & Warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退货 & 保修"])},
  "7 days easy return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七天无理由退货"])},
  "Change of mind is not applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of mind is not applicable"])},
  "100% Authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保证正品"])},
  "Products Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销量"])},
  "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
  "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏"])},
  "OVERVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
  "CUSTOMER REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户评论"])},
  "All Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有评论"])},
  "Slide Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide Show"])},
  "Praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好评"])},
  "Middle evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
  "Negative Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["差评"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
  "Add a review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一条评论"])},
  "添加成功！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加成功！"])},
  "Please select the product variant！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择好商品属性！"])},
  "Search For Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索商品"])},
  "PANIC BUYING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抢购"])},
  "Share your experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享一下你的体验"])},
  "New shipping address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增收货地址"])},
  "Edit shipping address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑收货地址"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
  "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
  "Full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满"])},
  "minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["减"])},
  "Order Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单结算信息"])},
  "The total amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])},
  "Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
  "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合计"])},
  "Shipping fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合计运费"])},
  "Amount payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应付金额"])},
  "Order Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交订单"])},
  "Agree to our company’s privacy policy and conditions of use, place an order immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意我们公司的隐私政策和使用条件，立即下单"])},
  "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "Set as default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设为默认"])},
  "Item(s) will be removed from cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品将从购物车中删除"])},
  "This product has reached the maximum purchaseable quantity!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该商品已达最大可购买数量!"])},
  "This is the minimum order quantity!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已是最小下单数量!"])},
  "Please select the product first!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先选择商品!"])},
  "Notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示"])},
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
  "Product Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品评分"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星"])},
  "Evaluation Sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价表"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
  "File size does not exceed 3M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件大小不要超过3M"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人中心"])},
  "Application has been submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请已提交"])},
  "Confirm application refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认申请退款"])},
  "After Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后服务"])},
  "Refund Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务类型"])},
  "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款"])},
  "REFUND&RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退货退款"])},
  "Submitted Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交数量"])},
  "Refund Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请金额"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
  "Confirm Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认提交"])},
  "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去支付"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "Confirm Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收货"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款"])},
  "Order Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付成功"])},
  "Order Dispatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品已出库"])},
  "Waiting for Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待收货"])},
  "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "Consignee Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收货人信息"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
  "Customer Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售信息"])},
  "Payment Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付信息"])},
  "paymentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付编码"])},
  "transactionID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])},
  "Commodity Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品号码"])},
  "Head Portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["头像"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知的"])},
  "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
  "New Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增地址"])},
  "Original Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原始密码"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
  "Refund Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退单号码"])},
  "Order Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单详情"])},
  "Applying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请中"])},
  "Agree to apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意申请"])},
  "Refuse to apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝申请"])},
  "Agree to a refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意退款"])},
  "Ticket no.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券号"])},
  "Valid time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效时间"])},
  "USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
  "spendAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满购金额"])},
  "Email Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱登录"])},
  "Mobile Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
  "Verification Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])},
  "Register Now >": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即注册"])},
  "l agree to the ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意 "])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务条款"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
  "Sign In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登 录"])},
  "Get Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取验证码"])},
  "Delete completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除完成"])},
  "Cart is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车为空"])},
  "Continue Shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续购物"])},
  "The": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您搜索的"])},
  "We are sorry, We cannot find any product for your searched category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，我们找不到任何与你搜索类别相关的产品"])},
  "Popular Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门搜索"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
  "has been generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已生成"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
  "You can pay in cash to our courier when you receivethe goods at your doorstep.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当您在家门口收到货物时，可以将现金支付给我们的快递员。"])},
  "Confirm Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认订单"])},
  "Please select payment method！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择支付方式！"])},
  "支付成功!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付成功!"])},
  "您的订单支付成功": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单支付成功"])},
  "请输入手机号码！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入手机号码！"])},
  "请输入正确的手机号码！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入正确的手机号码！"])},
  "请输入邮箱！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入邮箱！"])},
  "请输入正确的邮箱！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入正确的邮箱！"])},
  "请输入验证码！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入验证码！"])},
  "登录前请同意本服务条款及隐私政策！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录前请同意本服务条款及隐私政策！"])},
  "修改成功！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改成功！"])},
  "姓名请勿超出32字符！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名请勿超出32字符！"])},
  "Order Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单详情"])},
  "To pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去支付"])},
  "CLASSIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
  "No reviews yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无评论"])},
  "商品已下架！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品已下架！"])},
  "暂无数据！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无数据！"])},
  "已下架": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已下架"])},
  "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺货"])},
  "Product Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品详情"])},
  "Product Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品信息"])},
  "正在加载中，请稍后~": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载中，请稍后~"])},
  "提示!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示!"])},
  "是否支付？": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否支付？"])},
  "fold the sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折出售"])},
  "No More Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有更多优惠券了"])},
  "Amount to pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实付金额"])},
  "确认删除商品？": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认删除商品？"])},
  "确认删除选中商品？": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认删除选中商品？"])},
  "Coupons History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史记录"])},
  "请输入原因！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入原因！"])},
  "Please don't repeat apply for refund!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请勿重复提交退款申请!"])},
  "All products available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部商品可用"])},
  "Some products available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分商品可用"])},
  "Some products unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分商品不可用"])},
  "Above": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满"])},
  "Min.spend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满"])},
  "No limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无门槛"])},
  "此商品已下架！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此商品已下架！"])},
  "此属性商品库存不足！请刷新页面": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此属性商品库存不足！请刷新页面"])},
  "After sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后"])},
  "After sales order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后订单"])},
  "Application Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请单号"])},
  "Order information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单信息"])},
  "Refund Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款信息"])},
  "Application Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请时间"])},
  "Application Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请金额"])},
  "Reason for refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款原因"])},
  "Refund Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款金额"])},
  "您已领取了1张券": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已领取了1张券"])},
  "您已领取了": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已领取了"])},
  "张券": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["张券"])},
  "已达到上限，不可领取！": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已达到上限，不可领取！"])},
  "Specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品明细"])},
  "My favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我喜欢的"])},
  "Do you want to use this shipping address?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你想使用这个收货地址吗?"])},
  "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮费"])},
  "Remove from cart!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从购物车中删除!"])},
  "删除提示!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除提示!"])},
  "确认提示!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认提示!"])},
  "当前界面缺少参数!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current interface is missing parameters!"])},
  "has been confirmed.You can do the payment when you receive the product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经被确认.您可以在收到货时付款."])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
  "请填写所有选项!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写所有选项!"])},
  "Order Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单确认成功"])},
  "您已领取了此券.赶快去使用吧!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已领取了此券.赶快去使用吧!"])},
  "Enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
  "Unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未支付"])},
  "Confirm ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收货"])},
  "Express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看物流"])},
  "Review ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看评价 "])},
  "Do you confirm that you received the product?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你确定收到货了吗?"])},
  "Add Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评价"])},
  "文件大小不超过3M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件大小不超过3M"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "Remove Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除地址"])},
  "Save Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存成功"])},
  "请输入合法字符": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入合法字符"])},
  "COUPON COLLECTION CENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领券中心"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "common": {
    "productStatus": {
      "soldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已售罄!"])}
    }
  },
  "order": {
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "ORDERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交订单"])},
    "MyOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的订单"])},
    "MostPopular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最热门"])},
    "PaymentSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择付款方式"])},
    "PriceLowToHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格从低到高"])},
    "PriceHighToLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格从高到低"])}
  },
  "freight": {
    "Free freight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免运费"])}
  },
  "pay": {
    "successful": {
      "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的订单"])},
      "OrderConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认订单"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单已经确认。"])},
      "cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在收到产品后付款"])},
      "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请将款项转到以下账户"])}
    }
  },
  "heier": {
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击上面的按钮表示您同意我们公司的隐私政策和条款与条件。"])},
    "bankAccount": {
      "AccountInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户信息"])},
      "NameOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户名称"])},
      "BankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行名称"])},
      "AccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐号"])},
      "AccountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户类型"])},
      "IBANNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN号码"])},
      "Branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分公司"])},
      "BankAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行地址"])},
      "OrderValidityTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单生效时间"])},
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在截止时间前上传转账收据"])}
    }
  },
  "user": {
    "Homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
    "HOMEPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
    "CustomerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户服务"])},
    "Kefu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])}
  },
  "account": {
    "PWSuc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码设置成功"])}
  }
}