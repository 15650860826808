import api from '@/api/request'

// 获得奖励
export function getRewards() {
    return api.get(`/services/common/api/rewards/total`)
}

export function checkInClaim() {
    return api.post(`/services/common/api/mission/check-in/claim`)
}

export function getRecord(params) {
    return api.get(`/services/common/api/mission/day/records`, params)
}

export function getList() {
    return api.get(`/services/common/api/check-in/continuous/list`)
}

export function dailyClaim (params) {
    return api.post(`/services/common/api/reward/task/claim`, params)
}

export function earnedList(date) {
    return api.get(`/services/common/api/rewards/list/earned/${date}`)
}

export function usedList(date) {
    return api.get(`/services/common/api/rewards/list/used/${date}`)
}

export function expiredList(date) {
    return api.get(`/services/common/api/rewards/list/expired/${date}`)
}