import { defineStore } from 'pinia'
import api from '@/api'

let addressData = localStorage.getItem('addressData')
addressData = addressData ? JSON.parse(addressData) : []
const HsystemStore = defineStore('Hsystem', {
  state: () => {
    return {
      homeData: [],
      breadcrumbList: [], //分类列表
      menuData: [], // 目录tab
      addressData: addressData // 城市地址源数据
    }
  },
  actions: {
    // 获取目录
    getMenuDataList() {
      api.product
        .getCategories({
          'executed.equals': true
        })
        .then((res) => {
          if (res && res.length) {
            this.menuData = res.length === 1 ? res[0].children : res
            console.log(this.menuData, 'menuData')
          }
        })
        .catch((error) => {
          console.log('重复接口请求已拒绝', error) // 打印完整捕获的错误
        })
    },
    // 分类列表
    setBreadcrumbList(data) {
      console.log(data, 'setBreadcrumbList')
      this.breadcrumbList = data
    },
    // 获取目录
    setHomeData(data) {
      this.homeData = data
    },
    setAddressData(data) {
      this.addressData = data
    }
  }
})

export default HsystemStore
