// 动态切换主题
export function switchTheme(theme) {
  if (theme === 'dark') {
    document.documentElement.setAttribute('data-theme', 'dark') // 添加主题标识
  } else {
    document.documentElement.setAttribute('data-theme', 'orange')
  }
}

export function initTheme() {
  const savedTheme = process.env.VUE_APP_HOME_NAME === 'HAIERMALL' ? 'dark' : 'orange'
  switchTheme(savedTheme)
}

initTheme()

// switchTheme('orange')