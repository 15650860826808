// 用户中心相关
const userInfoRoutes = [
  {
    path: '/userinfo',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/userinfo',
        name: 'USERINFO',
        component: () => import('@/views/userinfo/index.vue'),
        meta: {
          cnName: '用户信息',
          enName: 'User Info'
        }
      },
      {
        path: '/couponIndex',
        name: 'COUPONINDEX',
        component: () => import('@/views/userinfo/coupon/CouponIndex.vue'),
        meta: {
          cnName: '优惠券',
          enName: 'Coupon'
        }
      },
      {
        path: '/userwallet',
        name: 'Wallet',
        component: () => import('@/views/userinfo/userwallet/Index.vue'),
        meta: {
          cnName: '用户钱包',
          enName: 'User Wallet'
        }
      },
      {
        path: '/withdraw',
        name: 'WithDraw',
        component: () => import('@/views/userinfo/userwallet/withdraw.vue'),
        meta: {
          cnName: '提现',
          enName: 'Withdraw'
        }
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/customerService/contactUs/index.vue'),
        meta: {
          cnName: '联系我们',
          enName: 'contactUs'
        }
      },
      {
        path: '/aboutPricoo',
        name: 'aboutPricoo',
        component: () => import('@/views/pricoo/aboutPricoo/index.vue'),
        meta: {
          cnName: '关于Pricoo',
          enName: 'aboutPricoo'
        }
      },
      {
        path: '/coin-list',
        name: 'coinList',
        component: () => import('@/views/userinfo/coinbase/index.vue'),
        meta: {
          cnName: '币种列表',
          enName: 'Coin list'
        }
      }
    ]
  }
]

export default userInfoRoutes