// 活动路由
const activityRoutes = [
  {
    path: '/zindagi',
    name: 'zindagi',
    component: () => import('@/views/activity/zindagi/index.vue'),
    meta: {
      cnName: '活动页面',
      enName: 'Activity Page'
    }
  },
  {
    path: '/congratulations/:slug',
    name: 'Congrats Form',
    component: () => import('@/views/activity/daily-lucky-draw/congrats'),
    meta: {
      cnName: '抽奖',
      enName: 'Lucky Draw'
    }
  },
  {
    path: '/lucky-draw',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/apply-lucky-draw',
        name: 'Lucky Draw',
        component: () => import('@/views/activity/daily-lucky-draw/index.vue'),
        meta: {
          cnName: '抽奖',
          enName: 'Lucky Draw'
        }
      },
      {
        path: '/lucky-no',
        name: 'Generate Lucky No',
        component: () => import('@/views/activity/daily-lucky-draw/components/GenerateLuckyNumber.vue'),
        meta: {
          cnName: '抽奖',
          enName: 'Lucky Draw'
        }
      },
      {
        path: '/winner-history',
        name: 'Winner History',
        component: () => import('@/views/activity/daily-lucky-draw/history/index.vue'),
        meta: {
          cnName: '抽奖',
          enName: 'Winner History'
        }
      },
      {
        path: '/user-history',
        name: 'User History',
        component: () => import('@/views/activity/daily-lucky-draw/history/auth.vue'),
        meta: {
          cnName: '抽奖',
          enName: 'User History'
        }
      },

    ]
  },
  // 其他活动相关的路由配置
]

export default activityRoutes
