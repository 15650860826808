import * as activity from './apis/activity'
import * as address from './apis/address'
import * as common from './apis/common'
import * as coupon from './apis/coupon'
import * as order from './apis/order'
import * as pay from './apis/pay'
import * as product from './apis/product'
import * as user from './apis/user'
import * as checkin from './apis/checkin'

export default {
  activity, // 活动
  address, // => 地址
  common, // => 公共
  coupon, // => 优惠卷
  product, // => 商品
  order, // => 订单
  pay, // => 购买
  user, // => 用户
  checkin // => 报到
}
